import React from "react";
import styled from "styled-components";
import Link from "next/link";
import dynamic from "next/dynamic";

import NavLink from "@components/navigation/links/NavLink";
import SVGLogo from "@components/navigation/SVGLogo";
import ProductNavigationRow from "@components/navigation/ProductNavigationRow";
import DesktopProductNavigation from "@components/navigation/DesktopProductNavigation";
import NakedButton from "@components/common/buttons/NakedButton";
import CartIcon from "@components/navigation/CartIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";

import { useRecoilValue } from "recoil";
import { userState } from "@atoms/user";
import { useRouter } from "next/router";

const NanoBar = dynamic(() => import("@components/navigation/NanoBar"), {
  ssr: false,
  loading: () => <div style={{ height: "34px", background: "#001F60" }} />,
});

const NavigationHeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 70px;
  background: white;
  padding: 0px 3rem;
  user-select: none;
  position: sticky;
  z-index: 999;
  top: 0px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.extraLightPrimary};
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    padding: 0px 1.5rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    height: 55px;
    padding: 0px 0rem;
  }
`;
const MobileMenuButton = styled.div`
  display: none;
  background: white;
  z-index: 99;
  cursor: pointer;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  border: none;
  & div {
    width: 26px;
    height: 3px;
    background: ${(props) => props.theme.colors.darkPrimary};
    margin: 5px 0px;
    border-radius: 10px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    display: inherit;
  }
`;

const LeftNav = styled.nav``;

const CenterNav = styled.nav`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const RightNav = styled.nav`
  right: 15px;
  padding-right: 45px;
  box-sizing: border-box;
  position: absolute;
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    padding-right: 38px;
  }
`;

const Logo = styled.a`
  display: block;
  background: white;
  padding: ${(props) => props.isHome && "0px 0px 31px 0px"};
  border-bottom-right-radius: 160px;
  border-bottom-left-radius: 160px;
  margin-top: ${(props) => props.isHome && "50px"};
  & svg {
    margin-top: ${(props) => props.isHome && "20px"};
    width: 194px;
    height: ${(props) => (props.isHome ? "76px" : "60px")};

    & path,
    polygon {
      transition: ${(props) => props.theme.transitions.standard};
      fill: ${(props) => props.theme.colors.primary};
    }
    :hover {
      & path,
      polygon {
        transition: ${(props) => props.theme.transitions.standard};
        fill: ${(props) => props.theme.colors.lightPrimary};
      }
    }
  }
  & p {
    font-size: 0px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    padding: 0px;
    margin-top: 0px;
    & svg {
      margin-top: 0px;
    }
    & svg {
      height: 45px;
      width: 100px;
    }
  }
`;

const ButtonStyle = styled.button`
  color: ${(props) => props.theme.colors.darkPrimary};
  font-family: ${(props) => props.theme.fonts.paragraph};
  font-weight: 600;
  margin-right: 27px;
  transition: ${(props) => props.theme.transitions.standard};
  letter-spacing: 0.008em;
  font-size: 1.65rem;
  text-decoration: none;
  text-transform: uppercase;
  position: inline;
  background: transparent;
  /* background: red; */
  border: none;
  cursor: pointer;
  outline: none;
  & svg {
    font-size: 1.43rem;
    padding-left: 1px;
    transition: ${(props) => props.theme.transitions.standard};
    transform: ${(props) => (props.desktopProductMenuOpen ? "rotate(-180deg)" : "rotate(0deg)")};
  }
  :hover {
    color: ${(props) => props.theme.colors.primary};
  }

  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    font-size: 1.6rem;
    margin-right: 9px;
    & svg {
      font-size: 1.1rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    margin-right: 7px;
    font-size: 1.45rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    display: none;
  }
`;

function NavigationHeader({
  toggleMobileMenuOpen,
  showProductRow,
  desktopProductMenuOpen,
  toggleDesktopProductMenuOpen,
  slimHeader,
}) {
  const user = useRecoilValue(userState);
  const router = useRouter();
  const isHome =
    router.pathname === "/" ||
    router.pathname === "/refill-ritual" ||
    router.pathname === "/sustainability" ||
    router.pathname === "/store-locator";
  return (
    <>
      {/* {!slimHeader && <NanoBar />} */}
      <NavigationHeaderWrapper>
        {!slimHeader && (
          <LeftNav>
            <NakedButton onClick={toggleMobileMenuOpen}>
              <MobileMenuButton>
                <div />
                <div />
                <div />
              </MobileMenuButton>
            </NakedButton>

            {/* <ButtonStyle
              desktopProductMenuOpen={desktopProductMenuOpen}
              onClick={toggleDesktopProductMenuOpen}
              id="shopButton"
            >
              Shop{" "}
              <FontAwesomeIcon
                id="shopButton2"
                icon={faChevronDown}
                width={0}
              />
            </ButtonStyle> */}
            <NavLink href="/products/shop/all">Shop</NavLink>
            <NavLink href="/refill-ritual">Refill Ritual</NavLink>
            <NavLink href="/sustainability">Sustainability</NavLink>
          </LeftNav>
        )}
        <CenterNav>
          <Link href="/" passHref>
            <span>
              {router.isReady && (
                <Logo isHome={isHome}>
                  <SVGLogo />
                  <p>Cleancult home page</p>
                </Logo>
              )}
            </span>
          </Link>
        </CenterNav>
        {!slimHeader && (
          <RightNav>
            {/* <NavLink realLink={true} href="htt ps://strongthumbs.com" storeLink={true}>
              GET FREE SAMPLES
            </NavLink> */}
            <NavLink href="/store-locator">Stores</NavLink>

            <NavLink href={user ? "/profile/your-next-box" : "/login"}>My Account</NavLink>
            <CartIcon />
          </RightNav>
        )}
        <DesktopProductNavigation
          desktopProductMenuOpen={desktopProductMenuOpen}
          toggleDesktopProductMenuOpen={toggleDesktopProductMenuOpen}
        />
      </NavigationHeaderWrapper>
      {showProductRow && <ProductNavigationRow showProductRow={true} />}
    </>
  );
}

export default NavigationHeader;
